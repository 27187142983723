.dropdown-menu {
  /* Колір фону меню */
  background-color: #f9f9f9;
  position: relative;
}

.dropdown-menu__toggle {
  /* Колір кнопки меню */
  background-color: #000000;
  color: white; /* Колір тексту кнопки */
  padding: 15px 20px; /* Збільшення розміру кнопки */
  border: none;
  cursor: pointer;
  font-size: 25px;
  text-align: center;
  font-weight: bold;
  position: relative; /* Додали позицію для лінії */
  display: inline-block; /* Додали блоковий тип для лінії */
  overflow: hidden; /* Приховуємо зайві частини лінії */
}

.dropdown-menu__toggle b {
  font-weight: bold; /* Застосовуємо жирний шрифт для тексту кнопки */
}

@keyframes lineAnimation {
  0% {
    transform: translateX(0); /* Початковий стан - лінія на початку */
  }
  25% {
    transform: translateX(-100%); /* Лінія досягає початку кнопки */
  }
  50% {
    transform: translateX(0%); /* Лінія на початку кнопки (залишається на місці) */
  }
  75% {
    transform: translateX(100%); /* Лінія від'їжджає від кнопки */
  }
  100% {
    transform: translateX(0%); /* Лінія від'їжджає від кнопки */
  }
}

@keyframes lineAnimation1 {
  0% {
    transform: translateX(0); /* Початковий стан - лінія на початку */
  }
  25% {
    transform: translateX(100%); /* Лінія досягає початку кнопки */
  }
  50% {
    transform: translateX(0%); /* Лінія на початку кнопки (залишається на місці) */
  }
  75% {
    transform: translateX(-100%); /* Лінія від'їжджає від кнопки */
  }
  100% {
    transform: translateX(0%); /* Лінія від'їжджає від кнопки */
  }
}


.dropdown-menu__toggle:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: green;
  animation: lineAnimation1 4s infinite; /* Застосовуємо анімацію до ліній */
}

.dropdown-menu__toggle:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: green;
  animation: lineAnimation 4s infinite; /* Застосовуємо анімацію до ліній */
}

.dropdown-menu__toggle:before {
  top: 0;
  left: 0;
}

.dropdown-menu__toggle:after {
  bottom: 0;
  left: 0;
  transform: translateX(-100%); /* Початковий стан - лінія на початку */
}

.dropdown-menu__list {
  /* Колір фону списку */
  background-color: #000000;
  border-radius: 20px;
  position: absolute;
  top: calc(100% + 1px); /* Відстань від кнопки меню до верхнього краю списку */
  left: 50px;
  transform: translateX(-50%); /* Вирівнювання меню по центру */
  list-style: none;
  padding: 0;
  margin: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.dropdown-menu__list li {
  padding: 10px;
  text-align: center;
}

.dropdown-menu__list li a {
  /* Колір тексту посилань */
  color: #ffffff;
  font-size: 30px; /* Збільшення розміру посилань */
  text-decoration: none;
  transition: color 0.5s; /* Плавний перехід кольору */
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.dropdown-menu__list li a:hover {
  color: #186b25; /* Колір тексту при наведенні на посилання */
}

.dropdown-menu__content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 0px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.9s ease, visibility 0.9s ease;
}

.dropdown-menu.open .dropdown-menu__content {
  opacity: 1;
  visibility: visible;
}

.dropdown-menu__list li a.active {
  color: #13441a; /* Колір активного посилання */
}

.dropdown-menu__toggle b {
  font-weight: bold; /* Застосовуємо жирний шрифт для тексту кнопки */
}