$color-purple: #000000;
$color-pink: #222222;
$color-gray: #9CA3AF;
$color-black: #1F2937;
$card-size: 23rem;

.app {
    //width: 100vw;
    //height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-image: linear-gradient(45deg, $color-purple, $color-pink);
    font-family: 'Montserrat', sans-serif;
    margin-top: 20px;
}

* {
    box-sizing: border-box;
}

.carousel {
    position: relative;
    width: $card-size;
    height: $card-size;

    @media (min-width: 200px) {
        width: 18rem;
        margin-bottom: 200px;
        margin-top: 100px;
    }

    @media (min-width: 400px) {
        width: 19rem;
        margin-bottom: 160px;
        margin-top: 100px;

    }

    @media (min-width: 800px) {
        width: 23rem;
        margin-bottom: 150px;
        margin-top: 100px;
    }

    @media (min-width: 1920px) {
        width: 23rem;
        margin-bottom: 100px;
        margin-top: 100px;
    }

    perspective: 700px;
    transform-style: preserve-3d;
}

.card-container {
    position: absolute;
    /*width: 100%;
    height: 100%;*/

    transform:
            rotateY(calc(var(--offset) * 50deg))
            scaleY(calc(1 + var(--abs-offset) * -0.4))
            translateZ(calc(var(--abs-offset) * -30rem))
            translateX(calc(var(--offset) / var(--abs-offset) * -5rem));
    filter: blur(calc(var(--abs-offset) * 1rem));
    transition: all 0.3s ease-out;

}

.card {
    width: 100%;
    height: 100%;
    padding: 2rem;
    background-color: hsl(110deg, 40%, calc(100% - var(--abs-offset) * 50%));
    border-radius: 1rem;
    color: $color-gray;
    text-align: justify;
    transition: all 0.3s ease-out;
    text-align: left;
    word-break: normal;

h2 {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin: 0 0 0.7em;
    color: $color-black;
}

p, h2 {
    transition: all 0.3s ease-out;
    opacity: var(--active);
}
}

.nav {
    color: white;
    font-size: 2rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    z-index: 2;
    cursor: pointer;
    user-select: none;
    background: unset;
    border: unset;

&.left {
     transform: translateX(-100%) translatey(-50%);
 }

&.right {
     right: 0;
     transform: translateX(100%) translatey(-50%);
 }
}
