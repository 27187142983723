.image-with-text-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .image-container {
    flex: 0 0 50%;
  }
  
  .text-container {
    flex: 0 0 50%;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
  }
  
  .text-container h2 {
    color: #333;
    font-size: 24px;
    font-weight: bold;
  }
  
  .text-container p {
    color: #666;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .buoy-image {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(-130%, 100%);
  }
  .buo-image {
    position: absolute;
    top: 2px;
    margin-left: 10px;
    right: 0;
    bottom: 0;
    transform: translate(-70%,20%);
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .center-text {
    max-width: 600px; /* Максимальна ширина текстового контенту */
    margin: 0 auto; /* Центрування контенту горизонтально */
  }