.menu {
    background-color: black;
    color: green;
    min-height: 100vh; /* Забезпечує повну висоту елементу .menu */
  }
  
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  
  .container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }