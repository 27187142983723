.service-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  .service-container {
    background-color: #000; /* Чорний колір фону */
    color: #3ad84c; /* Білий колір тексту */
  }
  
  .checkmark {
    color: #4dc247; /* Зелений колір */
    font-size: 3.2rem;
    line-height: 0;
    margin-right: 6px;
  }