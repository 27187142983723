.root {
  display: flex;
  justify-content: center;
}

.item {
  display: flex;

  @media (min-width: 300px) {
    flex-direction: column-reverse;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
}

.info {

}

.photo {
  img {
    max-height: 100%;
    width: 100%;
  }
}
